<template>
  <div class="logo">
    <svg
      :class="add"
      :width="width"
      :fill="color"
      viewBox="0 0 52 52"
      style="transform: rotate(-90deg)"
    >
      <g>
        <path d="M26,49.9997559c-9.9248047,0-18-8.074707-18-17.9995117V22.776123c0-1.5307617,
        1.2451172-2.7763672,2.7753906-2.7763672
        h30.4482422C42.7548828,19.9997559,44,21.2453613,44,22.776123v9.2241211C44,41.9250488,
        35.9248047,49.9997559,26,49.9997559z
        M10.7753906,21.9997559C10.3476563,21.9997559,10,22.3479004,10,22.776123v9.2241211
        c0,8.8222656,7.1777344,15.9995117,16,15.9995117s16-7.1772461,16-15.9995117V22.776123
        c0-0.4282227-0.3486328-0.7763672-0.7763672-0.7763672H10.7753906z"/>
        <path d="M25.9990234,40.9958496c-1.6542969,
        0-3-1.3457031-3-3v-4.0834961c0-0.1689453-0.0488281-0.3222656-0.1367188-0.4321289
        c-0.5566406-0.6953125-0.8632813-1.5776367-0.8632813-2.484375c0-2.2055664,
        1.7939453-4,4-4s4,1.7944336,4,4
        c0,0.90625-0.3066406,1.7880859-0.8642578,2.4838867c-0.0869141,
        0.109375-0.1357422,0.2636719-0.1357422,0.4326172v4.0834961
        C28.9990234,39.6501465,27.6533203,40.9958496,25.9990234,
        40.9958496z M25.9990234,28.9958496c-1.1025391,0-2,0.8969727-2,2
        c0,0.4604492,0.1464844,0.887207,0.4238281,1.2338867c0.3710938,
        0.4628906,0.5761719,1.0605469,0.5761719,1.6826172v4.0834961
        c0,0.5512695,0.4482422,1,1,1s1-0.4487305,1-1v-4.0834961c0-0.6210938,
        0.2041016-1.2182617,0.5742188-1.6816406
        c0.2783203-0.3481445,0.4257813-0.7749023,0.4257813-1.2348633
        C27.9990234,29.8928223,27.1015625,28.9958496,25.9990234,28.9958496z"/>
        <path d="M37.9990234,21.9997559c-0.5527344,
        0-1-0.4477539-1-1v-5.9995117c0-6.0654297-4.9345703-11-10.9990234-11
        c-6.0654297,0-11,4.9345703-11,11l-0.0009766,5.9995117c0,
        0.5522461-0.4482422,1-1,1l0,0c-0.5527344,0-1-0.4477539-1-1
        L13,15.0002441c0-7.168457,5.8320313-13,13-13s12.9990234,5.831543,12.9990234,13v5.9995117
        C38.9990234,21.552002,38.5517578,21.9997559,37.9990234,21.9997559z"/>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    color: String,
    width: String,
    add: String,
  },
};
</script>

<style scoped>

.loading {
  animation-name: loading;
  animation-duration: 0.6s;
  animation-direction: alternate-reverse;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0, 0, 0.4, 1);
}

@keyframes loading {
  0%   {transform: rotate(110deg)}
  100% {transform: rotate(-10deg)}
}
</style>
